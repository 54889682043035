import {
  firebaseConfig,
  auth,
  db,
  functions,
  storage,
  storageRef,
  FirebaseFieldValue,
  FirebaseTimestamp,
  firebaseAuth,
  moment,
} from '../config/main_config';
import { getCompanyImg } from './downloadFromSDK.module';
import { signOut, forceSignOut } from './logout.module';

export const onLoadFunction = function (uid, customerPlan = 'test') {
  console.log(customerPlan);
  auth.onAuthStateChanged((user) => {
    let userSessionTimeout;

    if (user === null && userSessionTimeout) {
      clearTimeout(userSessionTimeout);
      userSessionTimeout = null;
    } else if (user) {
      uid = auth.currentUser.uid;
      user.getIdTokenResult().then((idTokenResult) => {
        const authTime = idTokenResult.claims.auth_time * 1000;
        const sessioNDurationMilliseconds = 43200000;
        const expirationInMilliseconds = sessioNDurationMilliseconds - (Date.now() - authTime);
        userSessionTimeout = setTimeout(() => forceSignOut(), expirationInMilliseconds);
      });
      uid = user.uid;
      const userRef = db.collection('users').doc(uid);
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            window.currentUserID = doc.data().userID;
            window.userData = doc.data();

            // if (doc.data().roles.msp == true) {
            //   const hasSelectedTenancy =
            //     sessionStorage.getItem('web-selectedTenancy') != null
            //       ? sessionStorage.getItem('web-selectedTenancy')
            //       : false;

            //   console.log('hasSelectedTenancy', hasSelectedTenancy);
            //   if (!hasSelectedTenancy && !window.location.href.includes('msp-settings')) {
            //     window.location.href = 'msp-view.html';
            //   }
            // }

            if (doc.data().roles.admin == true) {
              getAdminCompanyImg();
              document.getElementById('cover').style.display = 'none';
            } else if (
              doc.data().roles.msp == true &&
              doc.data().roles.owner == true &&
              doc.data().mspImpersonation == true
            ) {
              window.companyID = doc.data().organisations[1];
              getCompanyImg(firebaseConfig, storageRef);
              if (customerPlan === 'isRFFR') {
                db.collection('customers')
                  .doc(window.companyID)
                  .collection('companySettings')
                  .doc('complianceModules')
                  .get()
                  .then(async (complianceModulesDoc) => {
                    await complianceModulesDoc;

                    if (complianceModulesDoc.exists) {
                      const complianceModules = complianceModulesDoc.data();
                      if (complianceModules.hasOwnProperty('ism')) {
                        complianceModules.ism.getProperty = function (prop) {
                          const lowerProp = prop.toLowerCase();
                          const key = Object.keys(this).find(
                            (key) => key.toLowerCase() === lowerProp,
                          );
                          return key ? this[key] : undefined;
                        };

                        console.log(complianceModules.ism);
                        console.log(
                          complianceModules.ism.getProperty('Enabled'),
                          complianceModules.ism.getProperty('enabled'),
                        );
                        // if ((complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('Enabled') && complianceModules.ism.Enabled === true) || complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('enabled') && complianceModules.ism.enabled === true) {
                        if (complianceModules.ism.getProperty('Enabled') === true) {
                          console.log('rffr');
                          customerPlan = 'ism';
                          window.customerPlan = customerPlan;

                          getTenancyInfo();
                        } else {
                          getTenancyInfo();                          
                        }
                      } else {
                        getTenancyInfo();
                      }
                    }
                  })
                  .catch((error) => console.error(error));
              } else {
                getTenancyInfo();
              }
              const header = document.querySelector('.header');
              const warningBanner = document.createElement('div');
              warningBanner.classList.add('warning-banner');
              warningBanner.id = 'warning-banner';
              warningBanner.style.height = '1.5rem';
              warningBanner.style.textAlign = 'center';
              warningBanner.style.backgroundColor = '#585555';
              warningBanner.style.width = '100%';
              warningBanner.style.marginBottom = '1.5rem';
              warningBanner.innerHTML = 'You are operating in MSP MODE in a customer tenancy.';
              warningBanner.style.color = 'white';
              header.style.marginBottom = '0 !important';
              header.style.marginTop = '-1.5rem';
              header.after(warningBanner);

              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

              var yyyy = today.getFullYear();
              var date = String(`${mm}-${dd}-${yyyy}`);
              const companyRef = db
                .collection('customers')
                .doc(window.companyID)
                .collection('accessLogs')
                .doc(date);
              // db.collection('customers')
              //   .doc(window.companyID)
              //   .get()
              //   .then(async (customerDoc) => {
              //     await customerDoc;

              //     if (customerDoc.exists) {
              //       const { bugReportingEnabled } = customerDoc.data();
              //       if (bugReportingEnabled === true) {
              //         const sidebar = document.querySelector('.simplebar-content');
              //         const bugReportLi = document.createElement('li');
              //         bugReportLi.classList.add('nav-item');
              //         bugReportLi.style.marginBottom = '0';
              //         bugReportLi.style.marginTop = 'auto';
              //         bugReportLi.innerHTML = `
              //         <a class="nav-link" href="https:////forms.clickup.com/18608490/f/hqwba-1082/2IRXMOJKV39XDR914K"
              //              target="_blank">
              //             Report a Bug/Issue
              //         </a>
              //         `;
              //         sidebar.appendChild(bugReportLi);
              //       }
              //     }
              //   });

              companyRef.get().then(async (companyDoc) => {
                await companyDoc;

                if (companyDoc.exists) {
                  const logKeys = Object.keys(companyDoc.data()).sort();
                  const getLastKeyInMap = (map) => map[map.length - 1].logID ?? 0;
                  const lastMapKey = getLastKeyInMap(logKeys);
                  console.log(lastMapKey);
                  const keyInt = parseInt(lastMapKey);
                  const newKey = parseInt(keyInt) + 1;
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                } else {
                  const newKey = '0';
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                }
              });
              document.getElementById('logoutMenu').innerHTML =
                '\
                  <div class="dropdown-header bg-light py-2">\
                    <div class="fw-semibold">Menu</div>\
                  </div>\
                  <a class="dropdown-item" onclick="signOut();">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-account-logout"></use>\
                    </svg>\
                    Leave Tenancy\
                  </a>';
              document.getElementById('cover').style.display = 'none';
            } else if (
              doc.data().roles.msp == true &&
              doc.data().roles.owner == true &&
              doc.data().mspImpersonation == false
            ) {
              window.companyID = doc.data().organisations[0];
              getCompanyImg(firebaseConfig, storageRef);
              if (customerPlan === 'isRFFR') {
                db.collection('customers')
                  .doc(window.companyID)
                  .collection('companySettings')
                  .doc('complianceModules')
                  .get()
                  .then(async (complianceModulesDoc) => {
                    await complianceModulesDoc;

                    if (complianceModulesDoc.exists) {
                      const complianceModules = complianceModulesDoc.data();
                      if (complianceModules.hasOwnProperty('ism')) {
                        complianceModules.ism.getProperty = function (prop) {
                          const lowerProp = prop.toLowerCase();
                          const key = Object.keys(this).find(
                            (key) => key.toLowerCase() === lowerProp,
                          );
                          return key ? this[key] : undefined;
                        };

                        console.log(complianceModules.ism);
                        console.log(
                          complianceModules.ism.getProperty('Enabled'),
                          complianceModules.ism.getProperty('enabled'),
                        );
                        // if ((complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('Enabled') && complianceModules.ism.Enabled === true) || complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('enabled') && complianceModules.ism.enabled === true) {
                        if (complianceModules.ism.getProperty('Enabled') === true) {
                          console.log('rffr');
                          customerPlan = 'ism';
                          window.customerPlan = customerPlan;

                          getTenancyInfo();
                        } else {
                          getTenancyInfo();                          
                        }
                      } else {
                        getTenancyInfo();
                      }
                    }
                  })
                  .catch((error) => console.error(error));
              } else {
                getTenancyInfo();
              }
              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

              var yyyy = today.getFullYear();
              var date = String(`${mm}-${dd}-${yyyy}`);
              const companyRef = db
                .collection('customers')
                .doc(window.companyID)
                .collection('accessLogs')
                .doc(date);
              // db.collection('customers')
              //   .doc(window.companyID)
              //   .get()
              //   .then(async (customerDoc) => {
              //     await customerDoc;

              //     if (customerDoc.exists) {
              //       const { bugReportingEnabled } = customerDoc.data();
              //       if (bugReportingEnabled === true) {
              //         const sidebar = document.querySelector('.simplebar-content');
              //         const bugReportLi = document.createElement('li');
              //         bugReportLi.classList.add('nav-item');
              //         bugReportLi.style.marginBottom = '0';
              //         bugReportLi.style.marginTop = 'auto';
              //         bugReportLi.innerHTML = `
              //         <a class="nav-link" href="https:////forms.clickup.com/18608490/f/hqwba-1082/2IRXMOJKV39XDR914K"
              //              target="_blank">
              //             Report a Bug/Issue
              //         </a>
              //         `;
              //         sidebar.appendChild(bugReportLi);
              //       }
              //     }
              //   });

              companyRef.get().then(async (companyDoc) => {
                await companyDoc;

                if (companyDoc.exists) {
                  const logKeys = Object.keys(companyDoc.data()).sort();
                  const getLastKeyInMap = (map) => map[map.length - 1].logID ?? 0;
                  const lastMapKey = getLastKeyInMap(logKeys);
                  console.log(lastMapKey);
                  const keyInt = parseInt(lastMapKey);
                  const newKey = parseInt(keyInt) + 1;
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                } else {
                  const newKey = '0';
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                }
              });
              document.getElementById('logoutMenu').innerHTML = `\
                  <div class="dropdown-header bg-light py-2">\
                    <div class="fw-semibold">Menu</div>\
                  </div>\
                  <a class="dropdown-item" href="msp-view.html">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-exit-to-app"></use>\
                    </svg>\
                    Access Customer Tenancies\
                  </a>\
                  <a class="dropdown-item" onclick="window.location = 'settings.html'">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-settings"></use>\
                    </svg>\
                    Settings\
                  </a>\
                  <a class="dropdown-item" onclick="signOut();">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-account-logout"></use>\
                    </svg>\
                    Logout\
                  </a>`;
              document.getElementById('cover').style.display = 'none';
            } else if (doc.data().roles.owner == true) {
              window.companyID = doc.data().organisations[0];
              getCompanyImg(firebaseConfig, storageRef);
              if (customerPlan === 'isRFFR') {
                db.collection('customers')
                  .doc(window.companyID)
                  .collection('companySettings')
                  .doc('complianceModules')
                  .get()
                  .then(async (complianceModulesDoc) => {
                    await complianceModulesDoc;

                    if (complianceModulesDoc.exists) {
                      const complianceModules = complianceModulesDoc.data();
                      if (complianceModules.hasOwnProperty('ism')) {
                        complianceModules.ism.getProperty = function (prop) {
                          const lowerProp = prop.toLowerCase();
                          const key = Object.keys(this).find(
                            (key) => key.toLowerCase() === lowerProp,
                          );
                          return key ? this[key] : undefined;
                        };

                        console.log(complianceModules.ism);
                        console.log(
                          complianceModules.ism.getProperty('Enabled'),
                          complianceModules.ism.getProperty('enabled'),
                        );
                        // if ((complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('Enabled') && complianceModules.ism.Enabled === true) || complianceModules.hasOwnProperty('ism') && complianceModules.ism.hasOwnProperty('enabled') && complianceModules.ism.enabled === true) {
                        if (complianceModules.ism.getProperty('Enabled') === true) {
                          console.log('rffr');
                          customerPlan = 'ism';
                          window.customerPlan = customerPlan;

                          getTenancyInfo();
                        } else {
                          getTenancyInfo();                          
                        }
                      } else {
                        getTenancyInfo();
                      }
                    }
                  })
                  .catch((error) => console.error(error));
              } else {
                getTenancyInfo();
              }

              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

              var yyyy = today.getFullYear();
              var date = String(`${mm}-${dd}-${yyyy}`);
              const companyRef = db
                .collection('customers')
                .doc(window.companyID)
                .collection('accessLogs')
                .doc(date);
              // db.collection('customers')
              //   .doc(window.companyID)
              //   .get()
              //   .then(async (customerDoc) => {
              //     await customerDoc;

              //     if (customerDoc.exists) {
              //       const { bugReportingEnabled } = customerDoc.data();
              //       if (bugReportingEnabled === true) {
              //         const sidebar = document.querySelector('.simplebar-content');
              //         const bugReportLi = document.createElement('li');
              //         bugReportLi.classList.add('nav-item');
              //         bugReportLi.style.marginBottom = '0';
              //         bugReportLi.style.marginTop = 'auto';
              //         bugReportLi.innerHTML = `
              //         <a class="nav-link" href="https:////forms.clickup.com/18608490/f/hqwba-1082/2IRXMOJKV39XDR914K"
              //              target="_blank">
              //             Report a Bug/Issue
              //         </a>
              //         `;
              //         sidebar.appendChild(bugReportLi);
              //       }
              //     }
              //   });

              companyRef.get().then(async (companyDoc) => {
                await companyDoc;

                if (companyDoc.exists) {
                  const logKeys = Object.keys(companyDoc.data()).sort();
                  const getLastKeyInMap = (map) => map[map.length - 1].logID ?? 0;
                  const lastMapKey = getLastKeyInMap(logKeys);
                  console.log(lastMapKey);
                  const keyInt = parseInt(lastMapKey);
                  const newKey = parseInt(keyInt) + 1;
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                } else {
                  const newKey = '0';
                  const userID = uid;
                  const page = window.location.href;
                  const time = FirebaseFieldValue.serverTimestamp();
                  companyRef.set(
                    {
                      [newKey]: {
                        logID: String(newKey),
                        performedBy: userID,
                        page,
                        performedAt: time,
                      },
                    },
                    {
                      merge: true,
                    },
                  );
                }
              });
              document.getElementById('logoutMenu').innerHTML =
                '\
                  <div class="dropdown-header bg-light py-2">\
                    <div class="fw-semibold">Menu</div>\
                  </div>\
                  <a class="dropdown-item" onclick="window.location = `settings.html`">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-settings"></use>\
                    </svg>\
                    Settings\
                  </a>\
                  <a class="dropdown-item" onclick="signOut();">\
                    <svg class="icon me-2">\
                      <use xlink:href="coreui/free.svg#cil-account-logout"></use>\
                    </svg>\
                    Logout\
                  </a>';
              document.getElementById('cover').style.display = 'none';
            } else if (doc.data().roles.user == true) {
              window.location = '/policy_reader/';
            }
          } else {
            console.log('No such document!');
            if (window.location.href.indexOf('onboarding') === -1) {
              window.location = 'login.html';
            }
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    } else {
      if (window.location.href.indexOf('onboarding') === -1) {
        window.location = 'login.html';
      }
    }
  });
};
